<template>
  <v-select
    ref="vSelect"
    class="w-full"
    :class="{'border-danger': danger}"
    v-model="selectedPaymentMethod"
    :multiple="false"
    :closeOnSelect="true"
    :append-to-body="appendToBody"
    :options="paymentMethods"
    :clearable="false"
    @input="$emit('input', selectedPaymentMethod)"
    @search:focus="appendToBody ? applyModalZIndexToDropDown() : () => {}">
    <template #option="pm">
      <div class="flex justify-between items-center">
        <span>
          {{ pm.billing_details.name }} -
          <i
            class="fab mr-1 text-xl"
            :class="`fa-cc-${cardIconByBrand(pm.card.brand)}`"></i>
          •••• {{ pm.card.last4 }}
        </span>
        <a
          v-if="pm.defaultPaymentMethod"
          href="#"
          class="mb-0 pointer-events-none">
          {{ $t('$General.Default') | lowercase }}
        </a>
      </div>
    </template>
    <template #selected-option="pm">
      <span>
        {{ pm.billing_details.name }} -
        <i
          class="fab mr-1 text-xl"
          :class="`fa-cc-${cardIconByBrand(pm.card.brand)}`"></i>
        •••• {{ pm.card.last4 }}
      </span>
      <template v-if="pm.defaultPaymentMethod">
        <span class="mx-2">-</span>
        <a
          href="#"
          class="mb-0 pointer-events-none font-bold">
          {{ $t('$General.Default') | lowercase }}
        </a>
      </template>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';

// Mixins
import commonVSelect from '@/views/modules/_mixins/commonVSelect';

/**
 * Component to select a payment method
 *
 * @module views/modules/_components/VSelectPaymentMethod
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} value - selected payment method
 * @vue-prop {Array<Object>} [paymentMethods=[]] - tenant payment methods
 * @vue-prop {Boolean} [appendToBody=false] - indicate if append select to body
 * @vue-data {Object} [selectedPaymentMethod={...}] - selected local payment method
 * @vue-events {string} cardIconByBrand - return a font awesome icon for a card brand
 */
export default {
  name: 'VSelectPaymentMethod',
  components: {
    vSelect,
  },
  mixins: [commonVSelect],
  props: {
    value: {
      type: Object,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: false,
    },
    danger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedPaymentMethod: this.value,
    };
  },
  methods: {
    cardIconByBrand(brand) {
      switch (brand) {
        case this.$enums.StripeEnums.CardBrands.DINERS_CLUB:
          return 'diners-club';
        case this.$enums.StripeEnums.CardBrands.UNION_PAY:
        case this.$enums.StripeEnums.CardBrands.UNKNOWN:
          return 'credit-card';

        default:
          return brand;
      }
    },
  },
};
</script>

<style scoped>

</style>
