<template>
  <vx-card
    no-shadow
    class="px-base">
    <template v-slot:no-body>
      <div
        v-if="recurrent && showInputIntervals"
        class="mb-6">
        <recurrent-input-interval
          v-model="selectedInterval"
          :inactive-intervals="inactiveIntervals"
        />

        <div
          v-if="$slots['after-recurrent']"
          class="mt-3">
          <slot name="after-recurrent"></slot>
        </div>
      </div>

      <div class="flex justify-between mb-base">
        <div>
          <p class="font-semibold">{{ productName }}</p>
          <small
            v-if="recurrent"
            class="text-grey">{{ billedRecurrentLabel }}</small>
        </div>
        <span class="font-semibold">{{ productPrice | dollar }}</span>
      </div>

      <div
        v-for="(item, index) in extraItems"
        :key="`${item.name}-${index}`"
        class="flex justify-between  mb-base">
        <div>
          <p class="font-semibold">{{ item.name }}</p>
          <small
            v-if="item.recurrent"
            class="text-grey">{{ $t('BilledMonthly') }}</small>
          <small
            v-else-if="item.onlyFirstPay"
            class="text-grey">{{ $t('OneOff') }}</small>
        </div>
        <span class="font-semibold">{{ item.price | dollar }}</span>
      </div>

      <div class="flex justify-between font-semibold">
        <span>{{ $t('$General.Subtotal') }}</span>
        <span>{{ subtotal | dollar }}</span>
      </div>

      <vs-divider></vs-divider>

      <div class="flex justify-between text-grey mb-2">
        <span>{{ $t('GstAmountLabel', { percent: gst }) }}</span>
        <span>{{ gstAmount | dollar }}</span>
      </div>

      <vs-divider />

      <div class="flex justify-between font-bold text-lg mb-base">
        <span>{{ $t('$General.Total') }}</span>
        <span>{{ total | dollar }}</span>
      </div>

      <div
        v-if="paymentMethods.length > 0"
        class="pt-3">
        <div class="flex justify-between mb-3">
          <label>{{ $t('PayWith') }}</label>
          <vs-checkbox
            v-model="payWithNewCard">
            {{ $t('PayWithNewCard') | lowercase }}
          </vs-checkbox>
        </div>
        <template v-if="!payWithNewCard">
          <v-select-payment-method
            v-model="selectedPaymentMethodLocal"
            :payment-methods="paymentMethods"
            append-to-body
            :danger="!!paymentFailedMsg"
            @input="inputSelectedPaymentMethod()"/>
          <span
            v-if="!!paymentFailedMsg"
            class="text-danger">{{ paymentFailedMsg }}</span>
        </template>
      </div>

      <div
        v-if="payWithNewCard"
        class="vx-card border no-shadow mt-5">
        <add-credit-card
          ref="addCreditCard"
          :addButton="false"
          :errorCard="!!paymentFailedMsg"
          @is-valid="(val) => isCreditCardValid=val"
          @change="$emit('input-payment-method')"/>
        <span
          v-if="!!paymentFailedMsg"
          class="text-danger">{{ paymentFailedMsg }}</span>
      </div>

      <div
        v-if="$slots['before-pay-button']"
        class="mt-5">
        <slot name="before-pay-button"></slot>
      </div>

      <vs-button
        :disabled="!isValidPay"
        class="w-full font-bold mt-base mb-5"
        @click="paymentIntent()">
        {{ $t('$General.Pay') }} {{ total | dollar }}
      </vs-button>
    </template>

  </vx-card>
</template>

<script>
// Components
import AddCreditCard from '@/views/modules/_components/AddCreditCard.vue';
import AppCheckoutRecurrentInputInterval from '@/views/modules/_components/app-checkout/AppCheckoutRecurrentInputInterval.vue';
import VSelectPaymentMethod from '@/views/modules/_components/VSelectPaymentMethod.vue';

// Mixins
import commonVSelect from '@/views/modules/_mixins/commonVSelect';

// Util
import enums from '@/enums';

/**
 * App checkout
 *
 * @module views/modules/components/AppCheckout
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} productName - name of product to buy
 * @vue-prop {number} productPrice - price of product to buy
 * @vue-prop {number} gst - gst applied to this purchase
 * @vue-prop {boolean} recurrent - indicate if the purchase is billed recurrent
 * @vue-prop {string} interval - input interval to use
 * @vue-prop {Array<string>} inactiveIntervals - inactive intervals
 * @vue-prop {boolean} showInputIntervals - indicate of show or no the input intervals
 * @vue-prop {Object[]} paymentMethods - available user payment methods
 * @vue-prop {Object} selectedPaymentMethod - entry selected payment methods for user
 * @vue-prop {Object[]} extraItems - extra items to show in the checkout
 * @vue-prop {string} selectedInterval - selected interval to use
 * @vue-data {Object} [selectedPaymentMethodLocal=selectedPaymentMethod] -
 * selected payment method for user
 * @vue-data {boolean} [payWithNewCard=false] -
 * indicate if the user want to pay enter a new credit card
 * @vue-data {boolean} [isCreditCardValid=false] - indicate if the new credit card is valid
 * @vue-computed {string} billedRecurrentLabel - recurring label to show
 * @vue-computed {number} gstAmount - gst amount applied to the purchase
 * @vue-computed {number} extraItemsTotal - total of extra items
 * @vue-computed {number} subtotal - subtotal
 * @vue-computed {number} total - total of purchase with gst
 * @vue-computed {boolean} isValidPay - indicate if valid to make the pay
 * @vue-computed {Object | null} defaultPaymentMethod - the default payment methods for the user
 * @vue-event {void} created - hook to set the selected payment methods to the default
 * @vue-event {void} paymentIntent -
 * called on pay button pressed to emit the event for processing the purchase
 */
export default {
  name: 'AppCheckout',
  i18n: {
    messages: {
      en: {
        BilledMonthly: 'Billed monthly',
        BilledQuarterly: 'Billed quarterly',
        BilledSemiAnnually: 'Billed semi-annually',
        BilledYearly: 'Billed yearly',
        OneOff: 'One-off',
        GstAmountLabel: 'GST ({percent}%)',
        PayWith: 'Pay with',
        PayWithNewCard: 'New card',
      },
    },
  },
  components: {
    AddCreditCard,
    RecurrentInputInterval: AppCheckoutRecurrentInputInterval,
    VSelectPaymentMethod,
  },
  mixins: [commonVSelect],
  props: {
    productName: {
      type: String,
      required: true,
    },
    productPrice: {
      type: Number,
      required: true,
    },
    gst: {
      type: Number,
      required: true,
    },
    recurrent: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: String,
      validator(value) {
        return Object.values(enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL).indexOf(value) !== -1;
      },
      default: enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.MONTH,
    },
    inactiveIntervals: {
      type: Array,
      required: false,
      validator(intervals) {
        return intervals.every(
          (interval) => Object.values(
            enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL,
          ).indexOf(interval) !== -1,
        );
      },
      default() {
        return [];
      },
    },
    showInputIntervals: {
      type: Boolean,
      required: false,
      default: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
    selectedPaymentMethod: {
      required: true,
    },
    extraItems: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    paymentFailedMsg: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedInterval: this.interval,
      selectedPaymentMethodLocal: this.selectedPaymentMethod,
      payWithNewCard: this.paymentMethods.length === 0,
      isCreditCardValid: false,
    };
  },
  computed: {
    billedRecurrentLabel() {
      switch (this.selectedInterval) {
        case this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.TRIMESTER:
          return this.$t('BilledQuarterly');
        case this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.SEMESTER:
          return this.$t('BilledSemiAnnually');
        case this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.YEAR:
          return this.$t('BilledYearly');

        default:
          return this.$t('BilledMonthly');
      }
    },
    gstAmount() {
      return this.subtotal * (this.gst / 100);
    },
    extraItemsTotal() {
      return this.extraItems.reduce((total, currentValue) => total + currentValue.price, 0);
    },
    subtotal() {
      return this.productPrice + this.extraItemsTotal;
    },
    total() {
      return this.subtotal + this.gstAmount;
    },
    isValidPay() {
      return !this.payWithNewCard || this.isCreditCardValid;
    },
    defaultPaymentMethod() {
      return this.paymentMethods.find(
        (method) => method.defaultPaymentMethod,
      ) || null;
    },
  },
  watch: {
    payWithNewCard() {
      this.isCreditCardValid = false;
    },
    selectedInterval(val) {
      this.$emit('update:interval', val);
    },
  },
  created() {
    this.selectedPaymentMethodLocal = this.defaultPaymentMethod;
    this.inputSelectedPaymentMethod();
  },
  methods: {
    async paymentIntent() {
      let payload = null;

      if (this.payWithNewCard) {
        payload = await this.$refs.addCreditCard.addCard();
      }

      this.$emit('payment-intent', payload);
    },
    inputSelectedPaymentMethod() {
      this.$emit('update:selected-payment-method', this.selectedPaymentMethodLocal);
      this.$emit('input-payment-method');
    },
  },
};
</script>
