<template>
  <div>
    <div
      v-if="filteredInterval.length > 2">
      <v-select
        v-if="selectMode"
        ref="vSelect"
        v-model="selectedInterval"
        class="w-full"
        :options="filteredInterval"
        append-to-body
        :multiple="false"
        :clearable="false"
        close-on-select
        :getOptionLabel="(option) => $t(`$Enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.${option}`)"
        @search:focus="applyModalZIndexToDropDown()"
      />

      <div
        v-else
        class="flex flex-wrap justify-between row-gap-4">
        <vs-radio
          v-for="interval in filteredInterval"
          :key="interval"
          v-model="selectedInterval"
          :vs-value="interval"
          class="">
        <span class="font-semibold text-gray-dark">
          {{ $t(`$Enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.${interval}`) }}
        </span>
        </vs-radio>
      </div>
    </div>
    <div
      v-else-if="thereAreTwoFilteredInterval"
      class="flex justify-center">
      <span class="font-bold">
        {{ $t(`$Enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.${firstPairedInterval}`) }}
      </span>
      <vs-switch
        v-model="pairedIntervals"
        class="mx-3"/>
      <span class="font-bold">
        {{ $t(`$Enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.${secondPairedInterval}`) }}
      </span>
    </div>
  </div>

</template>

<script>
import vSelect from 'vue-select';

// Mixins
import commonVSelect from '@/views/modules/_mixins/commonVSelect';

// Util
import enums from '@/enums';

/**
 * App checkout recurring interval input
 *
 * @module views/modules/_components/app-checkout/AppCheckoutRecurrentInputInterval
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - input interval
 * @vue-prop {Array<string>} inactiveIntervals - inactive intervals
 * @vue-prop {boolean} selectMode - indicate if show the select mode or no
 * @vue-data {string} selectedInterval - the selected interval
 * @vue-data {Array<string>} allIntervals - all intervals
 * @vue-data {boolean} pairedIntervals - value to represent paired intervals
 * @vue-computed {Array<string>} filteredInterval - filtered intervals by active
 * @vue-computed {boolean} thereAreTwoFilteredInterval - indicate if there are two intervals or no
 * @vue-computed {string} firstPairedInterval - first paired interval
 * @vue-computed {string} secondPairedInterval - second paired interval
 */
export default {
  name: 'AppCheckoutRecurrentInputInterval',
  components: {
    vSelect,
  },
  mixins: [commonVSelect],
  props: {
    value: {
      type: String,
      validator(value) {
        return Object.values(enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL).indexOf(value) !== -1;
      },
      default: enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.MONTH,
    },
    inactiveIntervals: {
      type: Array,
      required: false,
      validator(intervals) {
        return intervals.every(
          (interval) => Object.values(
            enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL,
          ).indexOf(interval) !== -1,
        );
      },
      default() {
        return [];
      },
    },
    selectMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedInterval: this.value,
      allIntervals: Object.values(this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL),
      pairedIntervals: false,
    };
  },
  computed: {
    filteredInterval() {
      return this.allIntervals.filter(
        (interval) => this.inactiveIntervals.indexOf(interval) === -1,
      );
    },
    thereAreTwoFilteredInterval() {
      return this.filteredInterval.length === 2;
    },
    firstPairedInterval() {
      return this.filteredInterval[0];
    },
    secondPairedInterval() {
      return this.filteredInterval[1];
    },
  },
  watch: {
    pairedIntervals(val) {
      if (val) {
        this.selectedInterval = this.secondPairedInterval;
      } else {
        this.selectedInterval = this.firstPairedInterval;
      }
    },
    selectedInterval(val) {
      this.$emit('input', val);
    },
  },
  created() {
    if (this.filteredInterval.indexOf(this.selectedInterval) === -1) {
      [this.selectedInterval] = this.filteredInterval;
    }
  },
};
</script>

<style scoped>

</style>
