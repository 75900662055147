// Services
import appSettingsService from '@/api/modules/app-settings.service';
import tenantService from '@/api/modules/tenant.service';

/**
 * mixins for purchase
 *
 * @module views/modules/mixins/purchase.mixin
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [showCheckout=false] - indicate if show or not the checkout modal
 * @vue-data {Object} appSettings - app settings
 * @vue-data {Object[]} paymentMethods - user payment methods
 * @vue-data {Object} selectedPaymentMethod - select payment method for user
 * @vue-computed {Object} defaultPaymentMethod - default payment method of user
 * @vue-computed {Object} lastPaymentMethodAdded - last payment method added of user
 * @vue-computed {number} appSettingsGst - GST from app settings
 * @vue-event {void} getAppSettings - get the app settings
 * @vue-event {void} getPaymentMethods - get the user payment methods
 * @vue-event {void} addNewCreditCard - add new credit card to tenant
 */
export default {
  data() {
    return {
      showCheckout: false,
      appSettings: null,
      paymentMethods: [],
      selectedPaymentMethod: null,
    };
  },
  computed: {
    defaultPaymentMethod() {
      return this.paymentMethods.find(
        (method) => method.defaultPaymentMethod,
      ) || null;
    },
    lastPaymentMethodAdded() {
      return this.paymentMethods.length > 0
        ? this.paymentMethods.reduce(
          (prev, current) => ((prev.created > current.created) ? prev : current),
        )
        : null;
    },
    appSettingsGst() {
      return this.appSettings ? this.appSettings.gst : 0;
    },
  },
  methods: {
    async getAppSettings() {
      const resp = await appSettingsService.get();
      this.appSettings = resp.data ? resp.data : {};
    },
    async getPaymentMethods() {
      const resp = await tenantService.getPaymentMethods();
      this.paymentMethods = resp.data ? resp.data : [];
      this.selectedPaymentMethod = this.defaultPaymentMethod;
    },
    async addNewCreditCard(creditCardPayload) {
      if (creditCardPayload) {
        if (creditCardPayload.error) {
          throw new Error('credit-card-error');
        }

        const resp = await tenantService.addCreditCard(creditCardPayload);
        this.paymentMethods = resp.data;
        this.selectedPaymentMethod = this.lastPaymentMethodAdded;
      }
    },
  },
};
